import axios from 'axios';
import APIHelper from 'dashboard/helper/APIHelper';

const state = {
  uploadHandler: null,
  audioTranscriptionEnabled: false,
  isAudioTranscriptionInitialized: false,
  multiuploadFiles: [],
  isSyncingContacts: false,
};

const mutations = {
  SET_UPLOAD_HANDLER($state, handler) {
    $state.uploadHandler = handler;
  },
  UPLOAD_ATTACHMENTS($state, attachments) {
    if ($state.uploadHandler) {
      $state.uploadHandler(attachments);
    }
  },
  SET_AUDIO_TRANSCRIPTION_ENABLED($state, isEnabled) {
    $state.audioTranscriptionEnabled = isEnabled;
  },
  SET_AUDIO_TRANSCRIPTION_INITIALIZED($state, isInitialized) {
    $state.isAudioTranscriptionInitialized = isInitialized;
  },
  SET_IS_SYNCING_CONTACTS($state, isSyncing) {
    $state.isSyncingContacts = isSyncing;
  },
  ADD_TO_MULTIUPLOAD($state, { attachments }) {
    attachments.forEach(attachment => {
      const fileName = attachment.data_url.split('/').pop();
      const lastDotIndex = fileName.lastIndexOf('.');
      const extension = lastDotIndex !== -1 ? fileName.slice(lastDotIndex + 1) : '';
      const nameWithoutExtension = lastDotIndex !== -1 ? fileName.slice(0, lastDotIndex) : fileName;
      
      $state.multiuploadFiles.push({
        attachment,
        name: fileName,
        extension,
        customName: nameWithoutExtension,
      });
    });
  },
  REMOVE_FROM_MULTIUPLOAD($state, index) {
    $state.multiuploadFiles.splice(index, 1);
  },
  CLEAR_MULTIUPLOAD($state) {
    $state.multiuploadFiles = [];
  },
};

const actions = {
  uploadAttachmentsToMvp({ commit }, { attachments, newFileName }) {
    commit('UPLOAD_ATTACHMENTS', { attachments, newFileName });
  },

  registerUploadHandler({ commit }, handler) {
    commit('SET_UPLOAD_HANDLER', handler);
  },

  unregisterUploadHandler({ commit }) {
    commit('SET_UPLOAD_HANDLER', null);
  },

  async syncMvpContacts({ commit, rootGetters }) {
    commit('SET_IS_SYNCING_CONTACTS', true);
    try {
      const accountId = rootGetters.getCurrentAccountId;
      const API = APIHelper(axios);
      
      const response = await API.post(
        `/api/v1/accounts/${accountId}/professional_works/contacts/sync`
      );
      
      return response.data;
    } catch (error) {
      if (error.response?.data?.error === 'MVP_NOT_CONNECTED') {
        const accountId = rootGetters.getCurrentAccountId;
        window.location.href = `/app/accounts/${accountId}/settings/mvp`;
      }
      throw error;
    } finally {
      commit('SET_IS_SYNCING_CONTACTS', false);
    }
  },

  addToMultiupload({ commit }, { attachments }) {
    commit('ADD_TO_MULTIUPLOAD', { attachments });
  },

  removeFromMultiupload({ commit }, index) {
    commit('REMOVE_FROM_MULTIUPLOAD', index);
  },

  clearMultiupload({ commit }) {
    commit('CLEAR_MULTIUPLOAD');
  },

  async updateAudioTranscriptionSetting({ commit, dispatch }, isEnabled) {
    try {
      await dispatch(
        'accounts/update',
        {
          audio_message_transcription_enabled: isEnabled.toString(),
        },
        { root: true }
      );
      commit('SET_AUDIO_TRANSCRIPTION_ENABLED', isEnabled);
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating audio transcription setting:', error);
      return false;
    }
  },

  async initializeAudioTranscriptionSetting({
    commit,
    state: $state,
    rootGetters,
  }) {
    if ($state.isAudioTranscriptionInitialized) {
      return;
    }

    try {
      const accountId = rootGetters.getCurrentAccountId;
      const account = rootGetters['accounts/getAccount'](accountId);
      const isEnabled =
        account.custom_attributes?.audio_message_transcription_enabled ===
        'true';

      commit('SET_AUDIO_TRANSCRIPTION_ENABLED', isEnabled);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error initializing audio transcription setting:', error);
    } finally {
      commit('SET_AUDIO_TRANSCRIPTION_INITIALIZED', true);
    }
  },

  async checkSyncStatus({ rootGetters }) {
    try {
      const accountId = rootGetters.getCurrentAccountId;
      const API = APIHelper(axios);
      const response = await API.get(`/api/v1/accounts/${accountId}`);
      return response.data.custom_attributes?.contact_sync_running;
    } catch (error) {
      return null;
    }
  },

  async checkMvpHealth({ rootGetters }) {
    try {
      const accountId = rootGetters.getCurrentAccountId;
      const API = APIHelper(axios);
      const response = await API.get(
        `/api/v1/accounts/${accountId}/professional_works/contacts/health_check`
      );
      return response.data;
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
};

const getters = {
  getAudioTranscriptionEnabled: $state => $state.audioTranscriptionEnabled,
  isAudioTranscriptionInitialized: $state => $state.isAudioTranscriptionInitialized,
  isSyncingContacts: $state => $state.isSyncingContacts,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
